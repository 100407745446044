<template>
  <div class="d-flex flex-column" style="height: calc(100vh - 2 * var(--bar-height)); overflow: hidden;">
    <v-container v-if="item && itemSrc" class="pt-10 pb-0" style="min-height: 104px;">
      <audio
        v-if="itemType === 'audio'"
        ref="player"
        :src="itemSrc"
        controls
        type="audio/mpeg"
        class="player"
        @play="playing = true"
        @pause="playing = false"
      />

      <div
        v-else-if="itemType === 'youtube'"
        class="mb-6"
      >
        <youtube
          ref="youtube"
          :video-id="itemId"
          fitParent
        />
      </div>

    </v-container>

    <v-container class="scrollable" style="flex: 1">
      <v-select
        v-if="cats"
        v-model="catId"
        :items="catsOpts"
        :clearable="catId !== null"
        label="Kategorie"
        filled
        hide-details
        class="mt-5"
      />
      <div v-else class="mb-3"/>

      <v-list dense class="playlist">
        <v-list-item-group
          v-model="idx"
          mandatory
          color="primary"
        >
          <v-list-item
            v-for="(li, i) in items"
            :key="i"
            v-show="!catId || !li.catIds || li.catIds.length === 0 || li.catIds.includes(catId)"
            @click="playItem"
          >
            <v-list-item-icon v-if="!editing">
              <v-icon :color="i === idx ? 'primary' : 'grey lighten-1'">{{ li.type === 'audio' ? 'mdi-music-circle' : 'mdi-youtube' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="li.title"/>
              <v-list-item-subtitle>{{ li.date | date }}{{ li.subtitle ? (', ' + li.subtitle) : '' }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="editing">
              <v-btn icon x-small @click.stop="editItem(li, i)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>

    <fab-btn @click="addPlayPause">
      <v-icon>{{ editing ? 'mdi-plus' : (playing ? 'mdi-pause' : 'mdi-play') }}</v-icon>
    </fab-btn>

    <v-dialog
      :value="showEditDlg"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer/>
          <v-btn icon dark @click="showEditDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-form v-if="showEditDlg">
            <v-text-field v-model="editItemObj.title" label="Titel"/>
            <v-text-field v-model="editItemObj.subtitle" label="Untertitel"/>
            <date-field v-model="editItemObj.date" label="Datum"/>
            <v-radio-group v-model="editItemObj.source" label="Quelle">
              <v-radio label="URL" value="url"/>
              <v-radio label="Datei" value="file"/>
            </v-radio-group>
            <v-text-field v-if="editItemObj.source === 'url'" v-model="editItemObj.url" label="URL zu MP3 Datei oder Youtube Video" type="url"/>
            <file-field v-else-if="editItemObj.source === 'file'" v-model="editItemObj.file" label="MP3 Datei" accept=".mp3"/>
            <div v-if="catsOpts">
              <v-select
                v-model="editItemObj.catIds"
                :items="catsOpts"
                :rules="!$root.isAdmin ? [v => v && v.length > 0 || 'Bitte Kategorien wählen'] : []"
                label="Kategorie / Kategorien"
                :placeholder="$root.isAdmin ? 'leer = in allen Kategorien' : null"
                persistent-placeholder
                chips
                multiple
              />
            </div>
            <div class="d-flex flex-column align-center justify-center mt-6">
              <v-btn color="primary" rounded :disabled="!isFormValid" :loading="saving" class="my-3" @click="save">Speichern</v-btn>
              <v-btn v-if="!editItemObj.add" color="error" text @click="del">Löschen</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'
import FabBtn from '@/components/base/FabBtn'
import DateField from '@/components/base/DateField'
import FileField from '@/components/base/FileField'

const YOUTUBE = 'https://youtu.be/'

export default {
  name: 'PlayerView',
  mixins: [FileMixin],
  components: { FabBtn, DateField, FileField },
  props: {
    config: Object
  },
  data () {
    return {
      items: null,
      idx: 0,
      playing: false,
      editing: false,
      catId: null,
      showEditDlg: false,
      editItemObj: null,
      saving: false,
      showDeleteDlg: false
    }
  },
  computed: {
    item () {
      return this.idx > -1 && this.items?.[this.idx]
    },
    itemSrc () {
      if (!this.item) return null
      return this.item.url || this.item.src || null
    },
    itemType () {
      if (!this.item) return null
      return this.item.type
    },
    itemId () {
      if (!this.itemType) return null
      return this.itemType === 'youtube'
        ? this.itemSrc.substring(YOUTUBE.length)
        : null
    },
    cats () {
      return this.config.cats && Object.keys(this.config.cats).length > 1
        ? this.config.cats
        : null
    },
    catsOpts () {
      if (!this.cats) return null
      return [
        { text: 'Alle', value: null },
        ...Object.keys(this.cats).map(catId => ({ text: this.cats[catId], value: catId }))
      ]
    },
    isFormValid () {
      return this.editItemObj && this.editItemObj.title && this.editItemObj.source &&
        (this.editItemObj.source === 'url' && this.editItemObj.url || this.editItemObj.source === 'file' && this.editItemObj.file) &&
        (!this.cats || this.$root.isAdmin || this.editItemObj.catIds)
    }
  },
  methods: {
    async load () {
      const doc = await this.$fb.db.doc('players/' + this.config.id).get()
      if (doc.exists) {
        const data = doc.data()
        this.items = data.items.map(i => ({
          ...i,
          type: i.url?.indexOf(YOUTUBE) > -1 ? 'youtube' : 'audio',
          src: null
        })) || []
      } else {
        this.items = []
      }
    },
    addPlayPause () {
      if (this.editing) {
        this.editItemObj = { add: true, source: 'url' }
        this.showEditDlg = true
      } else {
        if (!this.playing) {
          if (this.itemType !== 'youtube') {
            this.$refs.player.play()
          } else {
            this.$refs.youtube.player.playVideo()
          }
        } else {
          if (this.itemType !== 'youtube') {
            this.$refs.player.pause()
          } else {
            this.$refs.youtube.player.pauseVideo()
          }
        }
        this.playing = !this.playing
      }
    },
    playItem () {
      if (!this.editing) {
        setTimeout(() => {
          if (this.itemType !== 'youtube') {
            this.$refs.player.play()
          } else {
            this.$refs.youtube.player.playVideo()
          }
          this.playing = true
        }, 1000)
      }
    },
    edit () { // public
      this.editing = !this.editing
      if (!this.editing && this.idx < 0 && this.items.length > 0) {
        this.idx = 0
      }
    },
    editItem (item, idx) {
      this.idx = idx
      this.editItemObj = { ...item }
      this.showEditDlg = true
    },
    async save () {
      this.saving = true
      if (this.editItemObj.add) {
        delete this.editItemObj.add
        this.items.push({
          ...this.editItemObj
        })
      } else if (this.editItemObj.del) {
        this.items.splice(this.idx, 1)
      } else {
        this.items[this.idx] = {
          ...this.editItemObj
        }
      }
      this.items.sort((a, b) => a.date !== b.date ? (a.date < b.date ? 1 : -1) : (a.title < b.title ? -1 : 1))
      await this.$fb.db.doc('players/' + this.config.id).update('items', this.items)
      this.showEditDlg = false
      this.saving = false
      this.editing = false
      this.idx = 0
    },
    del () {
      this.editItemObj.del = true
      this.save()
    }
  },
  watch: {
    item (item) {
      if (item && item.source === 'file' && !item.src) {
        this.getFileURL(item.file.src)
          .then(src => {
            item.src = src
          })
      }
    }
  },
  created () {
    this.load()
  }
}
</script>

<style scoped>
.player {
  width: 100%;
}
.playlist .v-list-item__icon {
  margin-right: 16px;
  align-self: center;
}
.playlist .v-list-item__title {
  white-space: inherit;
}
</style>