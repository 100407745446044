<template>
  <v-file-input
    v-model="file"
    :label="label || $t('file')"
    :accept="accept"
    :loading="uploading > 0"
    filled
    outlined
    dense
    hide-details
    prepend-icon=""
    prepend-inner-icon="$file"
    class="file-input"
    @change="addFile"
    @click:clear="delFile"
  />
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'

const FILE_SIZE_LIMIT = 10485760 // 10MB

export default {
  name: 'FileField',
  mixins: [FileMixin],
  props: {
    value: Object, // { src, name, type }
    label: String,
    accept: String
  },
  data () {
    return {
      file: null,
      uploading: 0,
      progress: 0
    }
  },
  methods: {
    addFile (file) {
      if (!file) return

      if (file.size > FILE_SIZE_LIMIT) {
        this.$root.snack = {
          text: this.$t('file_too_big', { limit: 10 }),
          color: 'error'
        }
        return
      }
      
      this.uploading++
      this.saveUploadedFile(file, p => { this.progress = p }) // TODO: combine progresses
        .then(fileName => {
          this.uploading--
          this.$emit('input', {
            src: fileName,
            name: file.name,
            type: fileName.substr(fileName.lastIndexOf('.') + 1)
          })
        })
    },
    delFile () {
      this.$emit('input', null)
    }
  },
  watch: {
    uploading (n, o) {
      if (n === 0 && o === 1) {
        this.file = null // clear
      }
    }
  },
  created () {
    if (this.value && this.value.name) {
      this.file = this.value
    }
  }
}
</script>

<style>
.file-input .v-file-input__text {
  padding-top: 0 !important;
}
</style>